import React, { useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

// imaages
import AppDevlopment from '../Images/app-development.png';
import WebSite from '../Images/world-wide-web.png';
import GraphicDesign from '../Images/graphic-designer.png';
import Marketing from '../Images/social-media.png';
import Wordpress from '../Images/wordpress.png';
import sopify from '../Images/social (1).png';
import appdev from '../Images/App-Dev.jpg';
import webdev from '../Images/web-dev.jpg';
import market from '../Images/market.jpg';
import shopidev from '../Images/shopify.jpg';
import  wordpresdev from '../Images/wordpress.jpg'
import uiuxblog from '../Images/blog-ui-ux.jpg'

gsap.registerPlugin(ScrollTrigger);

function Services() {
    useEffect(() => {
        if (typeof gsap.context === 'function') {
            let ctx = gsap.context(() => {
                gsap.set(".photo:not(:first-child)", { opacity: 0.5 ,scale: 1, translateY: "-100%",});

                const animation = gsap.to(".photo:not(:first-child)", {
                    opacity: 1,
                    scale: 1,
                    duration: 1,
                    translateY: 0,
                    stagger: 1,
                    delay: 2,
                });

                ScrollTrigger.create({
                    trigger: ".gallery",
                    start: "top top",
                    end: "bottom bottom",
                    pin: ".rightblock",
                    animation: animation,
                    scrub: true,
                });
            });

            return () => ctx.revert();
        } else {
            console.warn("gsap.context() is not available. Please ensure you're using GSAP 3.11.0 or later.");
        }
    }, []);

    return (
        <>
            {/* Service start */}
            <section id='Service' className="py-80">
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={10} className='text-center section-header'>
                            <h2 className='fw-medium fs-4 text-b fst-italic mb-1 position-relative'>Our <span>Services</span></h2>
                            <p className='text-black fs-1 text-capitalize text-opacity-75 fw-bold'>
                               We provide best services
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="gallery d-flex" style={{ flexWrap: "wrap" }}>
                                <Row className="left">
                                    <Col>
                                        <Row>
                                            <Col lg={12} className="service-details d-flex flex-column text-center justify-content-center ">
                                                <div className='mx-auto mb-4 d-flex justify-content-center align-items-center'><Image src={AppDevlopment} alt="service" className='img-fluid' /></div>
                                                <h3 className='mb-3' >Application Development</h3>
                                                <p className='text-black text-opacity-50'>We offer full-stack development services, providing both front-end and back-end solutions. Our team is skilled in React, Angular, Node.js, Python, and more to create seamless, scalable, and robust applications</p>
                                            </Col>
                                            <Col lg={12} className="service-details d-flex flex-column text-center justify-content-center ">
                                            <div className='mx-auto mb-4 d-flex justify-content-center align-items-center'><Image src={WebSite} alt="service" className='img-fluid' /></div>
                                                <h3 className='mb-3' >WebSite Development</h3>
                                                <p className='text-black text-opacity-50'>Our full-stack development services cover both front-end and back-end solutions, delivering seamless and scalable applications. With expertise in React, Angular, Node.js, Python, and more, our team ensures robust, high-performing results.</p>
                                            </Col>
                                            <Col lg={12} className="service-details d-flex flex-column text-center justify-content-center ">
                                            <div className='mx-auto mb-4 d-flex justify-content-center align-items-center'><Image src={GraphicDesign} alt="service" className='img-fluid' /></div>
                                                <h3 className='mb-3' >UI/UX Design</h3>
                                                <p className='text-black text-opacity-50'>We provide comprehensive UI/UX design services, crafting user-friendly and visually appealing interfaces. Our team excels in creating intuitive, engaging, and responsive designs that enhance user experience across all platforms.</p>
                                            </Col>
                                            <Col lg={12} className="service-details d-flex flex-column text-center justify-content-center ">
                                            <div className='mx-auto mb-4 d-flex justify-content-center align-items-center'><Image src={Marketing} alt="service" className='img-fluid' /></div>
                                                <h3 className='mb-3' >Marketing</h3>
                                                <p className='text-black text-opacity-50'>We specialize in crafting tailored marketing strategies that elevate your brand. Our team leverages SEO, social media, and content marketing to boost engagement and drive measurable results.</p>
                                            </Col>
                                            <Col lg={12} className="service-details d-flex flex-column text-center justify-content-center ">
                                            <div className='mx-auto mb-4 d-flex justify-content-center align-items-center'><Image src={sopify} alt="service" className='img-fluid' /></div>
                                                <h3 className='mb-3' >Shopify</h3>
                                                <p className='text-black text-opacity-50'>Shopify Development and E-commerce Solutions: We build custom Shopify stores designed for seamless shopping experiences. Our expertise covers store setup, theme customization, and app integrations to help grow your online business efficiently.</p>
                                            </Col>
                                            <Col lg={12} className="service-details d-flex flex-column text-center justify-content-center ">
                                            <div className='mx-auto mb-4 d-flex justify-content-center align-items-center'><Image src={Wordpress} alt="service" className='img-fluid' /></div>
                                                <h3 className='mb-3' >Wordpress</h3>
                                                <p className='text-black text-opacity-50'>WordPress Design and Development: We specialize in creating dynamic WordPress websites that are visually stunning and highly functional. Our team focuses on user experience, SEO optimization, and responsive design to help your site stand out and perform effectively.</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className='p1 ms-4'>
                                        <Row className="rightblock d-flex vh-100 align-items-center justify-content-end ">
                                            <div className='position-relative box p-0'>
                                                <Col className="photo w-100 h-100 position-absolute">
                                                    <img src={appdev} alt="img-1" className='h-100 w-100' />
                                                </Col>
                                                <Col className="photo w-100 h-100 position-absolute">
                                                    <img src={webdev} alt="img-2" className='h-100 w-100' />
                                                </Col>
                                                <Col className="photo w-100 h-100 position-absolute">
                                                    <img src={uiuxblog} alt="img-3" className='h-100 w-100' />
                                                </Col>
                                                <Col className="photo w-100 h-100 position-absolute">
                                                    <img src={market} alt="img-2" className='h-100 w-100' />
                                                </Col>
                                                <Col className="photo w-100 h-100 position-absolute">
                                                    <img src={shopidev} alt="img-2" className='h-100 w-100' />
                                                </Col>
                                                <Col className="photo w-100 h-100 position-absolute">
                                                    <img src={wordpresdev} alt="img-2" className='h-100 w-100' />
                                                </Col>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row className="d-block d-sm-none">
                        <Col lg={12} className="details d-flex flex-column justify-content-center">
                            <h3>Full Stack Development</h3>
                            <p>We offer full-stack development services, providing both front-end and back-end solutions. Our team is skilled in React, Angular, Node.js, Python, and more to create seamless, scalable, and robust applications.We offer full-stack development services, providing both front-end and back-end solutions. Our team is skilled in React, Angular, Node.js, Python, and more to create seamless, scalable, and robust applications.</p>
                        </Col>
                        <Col lg={12} className="details d-flex flex-column justify-content-center">
                            <h3>UI/UX Design</h3>
                            <p>Our UI/UX design services focus on creating intuitive and engaging user experiences. We design visually stunning interfaces that enhance usability and improve customer satisfaction.We offer full-stack development services, providing both front-end and back-end solutions. Our team is skilled in React, Angular, Node.js, Python, and more to create seamless, scalable, and robust applications.</p>
                        </Col>
                        <Col lg={12} className="details d-flex flex-column justify-content-center">
                            <h3>Cloud Solutions</h3>
                            <p>Leverage the power of the cloud with our scalable and secure cloud solutions. From AWS to Azure, we help businesses move to the cloud and optimize their infrastructure.We offer full-stack development services, providing both front-end and back-end solutions. Our team is skilled in React, Angular, Node.js, Python, and more to create seamless, scalable, and robust applications.</p>
                        </Col>
                        <Col lg={12} className="details d-flex flex-column justify-content-center">
                            <h3>DevOps Services</h3>
                            <p>Achieve seamless development and operations integration with our DevOps services. We help automate and streamline workflows, ensuring faster time-to-market and continuous delivery.We offer full-stack development services, providing both front-end and back-end solutions. Our team is skilled in React, Angular, Node.js, Python, and more to create seamless, scalable, and robust applications.</p>
                        </Col>
                        <Col lg={12} className="details d-flex flex-column justify-content-center">
                            <h3>Mobile App Development</h3>
                            <p>We create high-performance mobile applications for both iOS and Android platforms, using the latest frameworks like React Native and Flutter to ensure cross-platform compatibility and stunning user experiences.We offer full-stack development services, providing both front-end and back-end solutions. Our team is skilled in React, Angular, Node.js, Python, and more to create seamless, scalable, and robust applications.</p>
                        </Col>
                        <Col lg={12} className="details d-flex flex-column justify-content-center">
                            <h3>Software Development</h3>
                            <p>We offer custom software development services tailored to meet your specific business needs. From web applications to enterprise software, our solutions are designed to optimize performance and scalability.We offer full-stack development services, providing both front-end and back-end solutions. Our team is skilled in React, Angular, Node.js, Python, and more to create seamless, scalable, and robust applications.</p>
                        </Col>
                    </Row> */}
                </Container>
            </section>
            {/* Service end */}
        </>
    );
}

export default Services;
