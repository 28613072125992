import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import mainvideo from '../video/backgroun.mp4';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';

import gsap from 'gsap';

export default function HeroSection() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth > 526); // 576px is the sm breakpoint
    };

    handleResize(); // Set initial state based on the current window size
    window.addEventListener('resize', handleResize); // Add event listener

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    const Navbar = gsap.timeline();

    return () => {
      Navbar.kill();
    };
  }, []);

  return (
    <>
      {/* Hero section start */}
      <section id='Home' className='hero-section'>
        <div className="video-background">
          <video
            autoPlay
            loop
            muted
            playsInline
            className='video'
          >
            <source src={mainvideo} type="video/mp4" />
          </video>
          <div className="overlay"></div>
          <Container className='video-txt'>
            <Row className='gy-md-4 my-0 my-sm-4 pt-lg-0 justify-content-center'>
              <Col lg={8} md={12} className='text-center'>
                <h6 className='fs-2 fw-bold mb-2'>
                  IT <b className='blue'>SERVICES</b> PROVIDER
                </h6>
                <h4 className='font-monospace fs-5 mb-3 highlight'>
                  “सब सुख लहै तुम्हारी सरना,{!isSmallScreen && <br />} तुम रक्षक काहू को डरना”
                </h4>
                <p className='text-br mb-0'>
                  <span className='highlight-text'>BecoderTechnology</span> is a premier IT services provider dedicated to delivering cutting-edge solutions across a wide spectrum of digital needs.
                  <span className='highlight-text'>Choose BecoderTechnology</span> for unparalleled service and exceptional results in the digital landscape.
                </p>
              </Col>
            </Row>
          </Container>

        </div>
      </section>
    </>
  );
}
